<template>
  <div>
    <pwd-contair>
      <div slot="menu">待发货</div>
      <div slot="pwdContair">
        <el-form
          class="transportform"
          ref="transportForm"
          :model="transportForm"
          :rules="transportFormRule"
        >
          <el-form-item>
            <el-radio v-model="transportForm.methods" :label="1"
              >1.途啦网络货运</el-radio
            >
          </el-form-item>
          <el-form-item label="运单号" label-width="80px" prop="netWorkOrder">
            <el-row :gutter="0">
              <el-col :span="8">
                <el-input
                  style="width: 293px"
                  v-model="transportForm.netWorkOrder"
                  placeholder="请输入运单号"
                  :disabled="netWorkDisabled"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-radio v-model="transportForm.methods" :label="2"
              >2.第三方物流</el-radio
            >
          </el-form-item>
          <el-row :gutter="12">
            <el-col :span="12">
              <el-form-item
                label="发货单"
                label-width="80px"
                prop="threePartiesInvoice"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.threePartiesInvoice"
                      placeholder="请输入发货单"
                      :disabled="threePartiesDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="收货单"
                label-width="80px"
                prop="threePartiesReceipt"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.threePartiesReceipt"
                      placeholder="请输入收货单"
                      :disabled="threePartiesDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-radio v-model="transportForm.methods" :label="3"
              >3.快递</el-radio
            >
          </el-form-item>
          <el-row :gutter="12">
            <el-col :span="12">
              <el-form-item
                label="快递公司"
                label-width="80px"
                prop="courieCompany"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.courieCompany"
                      placeholder="请输入快递公司"
                      :disabled="courieDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="发货单号"
                label-width="80px"
                prop="courieInvoice"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.courieInvoice"
                      placeholder="请输入收货单"
                      :disabled="courieDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item>
            <el-radio v-model="transportForm.methods" :label="4"
              >4.其他</el-radio
            >
          </el-form-item>
          <el-row :gutter="12">
            <el-col :span="12">
              <el-form-item
                label="发货单"
                label-width="80px"
                prop="otherInvoice"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.otherInvoice"
                      placeholder="请输入发货单"
                      :disabled="otherDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="收货单"
                label-width="80px"
                prop="otherReceipt"
              >
                <el-row :gutter="0">
                  <el-col :span="17">
                    <el-input
                      v-model="transportForm.otherReceipt"
                      placeholder="请输入收货单"
                      :disabled="otherDisabled"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-form-item>
            <div class="btn Mtp50 Pbm40">
              <el-button type="primary" @click="submitForm('transportForm')"
                >确认发货</el-button
              >
              <el-button type="info" plain @click="resetForm('transportForm')"
                >取消</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";

export default {
  components: { pwdContair },
  data() {
    return {
      transportForm: {
        methods: "",
        netWorkOrder: "",
        threePartiesInvoice: "",
        threePartiesReceipt: "",
        courieCompany: "",
        courieInvoice: "",
        otherInvoice: "",
        otherReceipt: "",
      },
     /*  transportFormRule: {
        netWorkOrder: [
          { required: true, message: "请输入运单号", trigger: "blur" },
        ],
        threePartiesInvoice: [
          { required: true, message: "请输入发货单", trigger: "blur" },
        ],
        threePartiesReceipt: [
          { required: true, message: "请输入收货单", trigger: "blur" },
        ],
        courieCompany: [
          { required: true, message: "请输入快递公司", trigger: "blur" },
        ],
        courieInvoice: [
          { required: true, message: "请输入发货单号", trigger: "blur" },
        ],
        otherInvoice: [
          { required: true, message: "请输入发货单号", trigger: "blur" },
        ],
        otherReceipt: [
          { required: true, message: "请输入收货单号", trigger: "blur" },
        ],
      }, */
      netWorkDisabled: false,
      threePartiesDisabled: false,
      courieDisabled: false,
      otherDisabled: false,
    };
  },
  /*  created() {
    this.radioChecked();
  }, */
  /* mounted: {
    method: function () {
      return this.transportForm.methods;
    },
  }, */
  watch: {
    "transportForm.methods": {
      handler: function () {
        //do something
        console.log(this.transportForm.methods);
        this.radioChecked();
      },
    },
  },
  methods: {
    radioChecked() {
      console.log(this.transportForm.methods);
      if (this.transportForm.methods == "1") {
        (this.netWorkDisabled = false), (this.threePartiesDisabled = true);
        this.courieDisabled = true;
        this.otherDisabled = true;
      } else if (this.transportForm.methods == "2") {
        this.netWorkDisabled = true;
        this.threePartiesDisabled = false;
        this.courieDisabled = true;
        this.otherDisabled = true;
      } else if (this.transportForm.methods == "3") {
        this.netWorkDisabled = true;
        this.threePartiesDisabled = true;
        this.courieDisabled = false;
        this.otherDisabled = true;
      } else if (this.transportForm.methods == "4") {
        this.netWorkDisabled = true;
        this.threePartiesDisabled = true;
        this.courieDisabled = true;
        this.otherDisabled = false;
      }
    },
    submitForm(transportForm) {
      this.$refs[transportForm].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(transportForm) {
      this.$refs[transportForm].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.transportform {
  padding-left: 44px;
}
.btn {
  text-align: center;
  .el-button {
    width: 160px;
  }
}
</style>